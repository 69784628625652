import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'
import Section from '../../../components/dai/Section'
import IconCard from '../../../components/dai/IconCard'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import PageNavigation from '../../../components/dai/PageNavigation'
import Hero from '../../../components/dai/Hero'
import { BoldText } from '../../../components/dai/BoldText'
import LinkCTA from '../../../components/dai/LinkCTA'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'

import ShoppingCart from '../../../images/dai/icons/assets/shopping-cart.svg'
import Coins from '../../../images/dai/icons/assets/coins.svg'
import Globe from '../../../images/dai/icons/assets/globe.svg'
import Page from '../../../images/dai/icons/assets/page.svg'
import ShieldCheckmark from '../../../images/dai/icons/assets/shield-checkmark.svg'
import Capital from '../../../images/dai/icons/assets/capital.svg'

export default function IndustryReports() {
  return (
    <LayoutDAI
      freeAudit
      showIndustryReports
      pagePath={{
        parent: 'industry-reports',
        child: '',
        freeAuditHeading: 'Uncover even more accessibility barriers with an Expert Audit',
      }}
    >
      <SEO
        title="Industry Reports | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/industry-reports/"
        desc="We reviewed 3-5 of the top brands in the retail, financial services, travel, media, insurance & government to get a complete picture of their accessibility."
        bannerUrl="https://images.prismic.io/audioeye-web/5066b0a9-6529-490b-bb93-5afc49f47c64_industry-reports.jpg?auto=compress,format"
        imageAlt="Traffic jam on a highway with billboards in the background featuring industries such as retail and financial services."
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/industry-reports/`}
      />
      <Hero
        image={
          <StaticImage
            src="../../../images/dai/hero/industry-reports.png"
            alt="Traffic jam on a highway with billboards in the background featuring industries such as retail and financial services."
          />
        }
        noPaddingBottomMobile
      >
        <Text largeCapsInter color="orange900">
          INDUSTRY REPORTS
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Industry Reports">
          Where does each industry fall short on accessibility?
        </Heading1>
        <Space height={32} />
        <Text>
          Our automated scan tested 25 WCAG criteria at scale across entire sites. However, that still leaves complex
          accessibility barriers that automation alone cannot identify.
        </Text>
        <Space height={32} />
        <Text>
          That’s where our expert human testers come into play. Although they can’t review hundreds of pages in real
          time, they can focus on critical use cases for each industry — like checkout flows for retailers or booking
          calendars for travel sites.
        </Text>
        <Space height={32} />
      </Hero>
      <Section paddingBottom="lg" constraint="lg" innerConstraint="md">
        <Text>
          For the industries below, our team of certified accessibility experts (along with members of the disability
          community) reviewed 3-5 of the top brands in each space to build a more complete picture of their
          accessibility.
        </Text>
        <Space height={32} />
        <Text>
          <BoldText>
            And while the number of errors uncovered in these audits is not as high as we find in automated scans, they
            often have a bigger impact on a person’s ability to accomplish key tasks or reach their digital destination.
          </BoldText>
        </Text>
      </Section>
      <Section paddingTop="md" paddingBottom="md" constraint="lg" backgroundColor="orange200">
        <Heading2 heading2>Key insights by industry</Heading2>
        <Space height={32} />
        <Text>
          Find out the most common accessibility barriers by industry. Plus, learn what expert audits of the top 3-5
          companies in each industry revealed about the user experience for people with disabilities.
        </Text>
        <Space height={32} />
        <Columns unorderedList>
          <Column size={8} listItem>
            <IconCard flex>
              <ShoppingCart />
              <Space height={16} />
              <LinkCTA text="Retail" to="/digital-accessibility-index/industry-reports/retail/" />
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard flex>
              <Coins />
              <Space height={16} />
              <LinkCTA text="Financial Services" to="/digital-accessibility-index/industry-reports/finance/" />
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard flex>
              <Globe />
              <Space height={16} />
              <LinkCTA text="Travel" to="/digital-accessibility-index/industry-reports/travel/" />
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard flex>
              <Page />
              <Space height={16} />
              <LinkCTA text="Media" to="/digital-accessibility-index/industry-reports/media/" />
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard flex>
              <ShieldCheckmark />
              <Space height={16} />
              <LinkCTA text="Insurance" to="/digital-accessibility-index/industry-reports/insurance/" />
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard flex>
              <Capital />
              <Space height={16} />
              <LinkCTA text="Government" to="/digital-accessibility-index/industry-reports/government/" />
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/key-insights/form-accessibility-tips/"
        previousPageText="Form Accessibility Tips"
        nextPageLink="/digital-accessibility-index/industry-reports/retail/"
        nextPageText="Retail"
      />
    </LayoutDAI>
  )
}
